import Roles from '@/constants/Roles';
import Routes from '@/constants/Routes';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useMemo, useCallback } from 'react';

const useAuth = () => {
    const { user, checkSession, isLoading, error } = useUser() as any;

    const handleLogout = useCallback(() => {
        window.location.href = Routes.LOGOUT;
    }, []);

    if (error) {
        handleLogout();
    }

    return useMemo(() => {
        const session = user?.session ?? {};
        const isAuthenticated = Boolean(session.accessToken);
        const isAdmin = session.role === Roles.ADMIN;

        return {
            user: user?.user,
            update: checkSession,
            isLoading,
            error,
            accessToken: session.accessToken,
            isAuthenticated,
            role: session.role,
            isAdmin,
            logout: handleLogout,
        };
    }, [user?.session, user?.user, checkSession, isLoading, error, handleLogout]);
};

export default useAuth;
